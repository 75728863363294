import { db } from '@/utils/firebase.js'
import dynStorage from '@/utils/storage-dynamic'

const mockup_customer_default_dashboard_quick_links = ['account', 'shipping_details', 'payment_methods', 'payment_history'] // == getters.my_app_home_cards item.title

const state = {
    mockup_user: {},
}

const getters = {
	mockup_user: (state) => state.mockup_user,
	mockup_customer: (state, getters) => state.mockup_user?.[getters.current_account_uid] || {},

    mockup_customer_recaps_shown: (state, getters) => getters.mockup_customer?.web_recaps_shown || [],
    mockup_customer_feedback: (state, getters) => getters.mockup_customer?.web_feedback || {},

    mockup_customer_dashboard_quick_links: (state, getters) => getters.mockup_customer?.web_dashboard_quick_links || mockup_customer_default_dashboard_quick_links,
    mockup_customer_all_available_dashboard_quick_links: (state, getters) => {
        const my_app_home_cards = getters.my_app_home_cards

        const mockup_customer_all_available_dashboard_quick_links = my_app_home_cards.map(my_app_home_card => ({
            ...my_app_home_card,
            is_on_dashboard_quick_link: getters.mockup_customer_dashboard_quick_links.includes(my_app_home_card.title),
        }))

        if (!getters.is_account_type_activated) {
            return mockup_customer_all_available_dashboard_quick_links.filter(available_dashboard_quick_link => available_dashboard_quick_link.title != 'redeem_voucher')
        }

        return mockup_customer_all_available_dashboard_quick_links

    },
    mockup_customer_dashboard_quick_links_to_show: (state, getters) => getters.mockup_customer_all_available_dashboard_quick_links.filter(available_dashboard_quick_link => available_dashboard_quick_link.is_on_dashboard_quick_link),
    mockup_customer_dashboard_quick_links_to_show_titles: (state, getters) => getters.mockup_customer_dashboard_quick_links_to_show.map(dashboard_quick_link_to_show => dashboard_quick_link_to_show.title),
    
    top_up_auto: (state, getters) => getters.mockup_customer?.['top-up-auto'],
    is_mockup_customer_hide_external_ID_promt: (state, getters) => Boolean(getters.mockup_customer?.['HideExternalIDPromt']),
    onboarding: (state, getters) => getters.mockup_customer?.['onboarding'],
    mockup_user_language: (state, getters) => getters.mockup_user?.['lang'],
    is_accepted_terms: (state, getters) => getters.mockup_user?.['is-accepted-terms'],

    mockup_user_is_welcome_tour_shown: (state) => Boolean(state.mockup_user?.is_web_welcome_tour_shown),
    mockup_user_v_tour: (state) => state.mockup_user?.v_tour || {},
    mockup_user_shown_whats_new_last_version: (state, getters) => getters.mockup_user?.shown_whats_new_last_version || null,
    mockup_user_shown_features_versions: (state, getters) => getters.mockup_user?.shown_features_versions || [],

    my_app_home_cards: (state, getters) => {
        const my_app_home_cards = [
            {
                icon: 'account-circle',
                title: 'account',
                text: 'manage_your_login_details',
                to: { name: 'profile-personal-data' }
            },
            {
                icon: 'truck-fast',
                title: 'shipping_details',
                text: 'manage_your_shipping_details',
                to: { name: 'profile-shipping-details' }
            },
            {
                icon: 'bag-happy',
                title: 'order_history',
                text: 'manage_your_eStore_orders',
                to: { name: 'profile-order-history' }
            },
            {
                icon: 'pay-bill',
                title: 'pay_bill_upper',
                text: 'pay_for_your_services',
                to: { name: 'profile-account-pay-bill' }
            },
            {
                icon: 'companions',
                title: 'customer_groups',
                text: 'manage_your_customer_groups',
                to: { name: 'profile-customer-groups' }
            },
            {
                icon: 'companions',
                title: 'mobile_groups',
                text: 'manage_your_mobile_groups',
                to: { name: 'profile-mobile-groups' }
            },
            {
                icon: 'wallet-cards',
                title: 'payment_methods',
                text: 'manage_your_payment_methods',
                to: { name: 'profile-balance-payment-methods' }
            },
            {
                icon: 'sand-clock',
                title: 'payment_history',
                text: 'view_your_repayment_history',
                to: { name: 'profile-balance-payments-history' }
            },
            {
                icon: 'receipt-text',
                title: 'invoices',
                text: 'manage_your_invoices',
                to: { name: 'profile-account-invoices' }
            },
            {
                icon: 'receipt',
                title: 'receipts',
                text: 'manage_your_receipts',
                to: { name: 'profile-account-receipts' }
            },
            {
                icon: 'ticket-star',
                title: 'redeem_voucher',
                text: 'redeem_your_voucher',
                to: { name: getters.is_account_type_activated ? 'profile-redeem-voucher' : 'profile-my-app-home' }
            },
        ]

        return my_app_home_cards.filter(card => {
            if (
                card.to.name == 'profile-account-invoices' && !getters.is_app_environment_invoices_allowed ||
                card.to.name == 'profile-account-receipts' && !getters.is_app_environment_receipts_allowed ||
                card.to.name == 'profile-account-pay-bill' && !getters.is_app_environment_show_pay_bill_on_home ||
                card.to.name == 'profile-mobile-groups' && (!getters.is_app_environment_enabled_mobile_groups_menu_item || !getters.is_account_type_activated) ||
                card.to.name == 'profile-customer-groups' && !getters.is_app_environment_enabled_customer_groups_menu_item ||
                card.title == 'redeem_voucher' && !getters.is_app_environment_enabled_voucher_menu_item
            ) {
                return false
            }

            return true
        })
    },
}

const mutations = {
	saveMockupUser(state, mockup_user) {

    	state.mockup_user = mockup_user

    	if (mockup_user) {
        	dynStorage.set('mockup-user', mockup_user, null, true)
    	} else {
        	dynStorage.remove('mockup-user')
    	}
  	},
}

const actions = {
	mockupCustomer({ getters, commit, dispatch }) {
    	const user_uuid = getters.auth_user?.uid
    	const customer_uuid = getters.current_account_uid

    	if (user_uuid) {
      		return db
        	.doc(`users/${user_uuid}`)
        	.get()
        	.then((user) =>
          		user.exists
            	? Promise.resolve(user)
            	: db
                	.collection('users')
                	.doc(user_uuid)
                	.set({})
                	.then(() => db.doc(`users/${user_uuid}`).get()),
        	)
        	.then((user) => {
          		if (!customer_uuid) return Promise.resolve(user)

          		const mockup_user = user.data()

          		return !(customer_uuid in mockup_user)
            		? db
                		.collection('users')
                		.doc(user_uuid)
                		.set({
                  			...mockup_user,
                  			[customer_uuid]: {
                    			notifications: [],
                    			policy: true,
                    			'top-up-auto': {
                      				amount: 0,
                      				days: 0,
                      				status: false,
                      				type: 'EVERY_DAY',
                    			},
                  			},
                		})
                		.then(() => db.doc(`users/${user_uuid}`).get())
            		: Promise.resolve(user)
        	})
        	.then((user) => {
          		commit('saveMockupUser', user.data())

          		const mockup_user_language = getters.mockup_user_language
          		const local_storage_language = dynStorage.get('language', true)

          		if (local_storage_language && mockup_user_language !== local_storage_language) {
              		dispatch('updateMockupUser', { 'lang': local_storage_language })
          		} else if (!local_storage_language && mockup_user_language) {
              		dispatch('SetLanguage', { language: mockup_user_language, updateMockupUserLanguage: false})
          		}

          		return Promise.resolve(getters.mockup_customer)

        	})
        	.catch((error) => {
          		console.warn('mockupCustomer error', error)
          		console.dir(error)

          		return Promise.resolve(getters.mockup_customer)
        	})
    	} else {
      		return Promise.resolve(getters.mockup_customer)
    	}
  	},

  	saveMockupCustomer({ getters, commit, dispatch }, data) {
    	const user_uuid = getters.auth_user?.uid
    	const customer_uuid = getters.current_account_uid

    	const mockup_user = getters.mockup_user
    	const mockup_customer = getters.mockup_customer

    	const customer_data = {
      		...mockup_customer,
      		...data,
    	}

    	const user = {
      		...mockup_user,
      		[customer_uuid]: customer_data,
    	}

    	return db
      			.collection('users')
      			.doc(user_uuid)
      			.update(user)
      			.then(() => {
        			commit('saveMockupUser', {
          				...user,
          				[customer_uuid]: {
            				...customer_data,
          				},
        			})
      			})
  	},

  	updateMockupUser({ getters, commit }, data) {
    	const user_uuid = getters.auth_user?.uid
    	const mockup_user = getters.mockup_user

    	const user = {
        	...mockup_user,
        	...data,
      	}

    	return db
      			.collection('users')
      			.doc(user_uuid)
      			.update(user)
      			.then(() => {
        			commit('saveMockupUser', {
          				...user,
        			})
      			})
      			.catch(error => {
        			console.log('updateMockupUser error', error)
        			return Promise.reject(error)
      			})
  	},

  	saveMockupAutoTopUp({ dispatch }, top_up_auto) {
    	return dispatch('saveMockupCustomer', { 'top-up-auto': top_up_auto }).catch(error => {
            console.log('saveMockupAutoTopUp error', error)
        })
  	},

  	saveMockupLanguage({ dispatch }, language) {
    	return dispatch('updateMockupUser', { 'lang': language }).catch(error => {
            console.log('saveMockupLanguage error', error)
        })
  	},

    saveMockupHideExternalIDPromt({ dispatch }, HideExternalIDPromt) {
    	return dispatch('saveMockupCustomer', { HideExternalIDPromt }).catch(error => {
            console.log('saveMockupHideExternalIDPromt error', error)
        })
  	},

    saveMockupCustomerRecapsShown({ dispatch }, web_recaps_shown) {
    	return dispatch('saveMockupCustomer', { web_recaps_shown }).catch(error => {
            console.log('saveMockupCustomerRecapsShown error', error)
        })
  	},

    saveMockupCustomerFeedback({ dispatch }, web_feedback) {
    	return dispatch('saveMockupCustomer', { web_feedback }).catch(error => {
            console.log('saveMockupCustomerFeedback error', error)
        })
  	},

    saveMockupCustomerDashboardQuickLinks({ dispatch }, web_dashboard_quick_links) {
    	return dispatch('saveMockupCustomer', { web_dashboard_quick_links }).catch(error => {
            console.log('saveMockupCustomerDashboardQuickLinks error', error)

            return Promise.reject(error) // to show Error on the setup-dashboard-quick-links dialog
        })
  	},

    saveMockupIsWelcomeTourShown({ dispatch }, is_web_welcome_tour_shown) {
    	return dispatch('updateMockupUser', { is_web_welcome_tour_shown }).catch(()=>{})
    },
    
    saveMockupVTour({ dispatch }, v_tour) {
    	return dispatch('updateMockupUser', { v_tour }).catch(()=>{})
    },
    
    saveMockupShownWhatsNew({ dispatch }, mockup_user_whats_new) {
        if (!mockup_user_whats_new) return Promise.resolve();

    	return dispatch('updateMockupUser', mockup_user_whats_new)
  	},
}

export default {
  	state,
  	getters,
  	mutations,
  	actions,
}
