import axios from 'axios';
import { MEGA_BASE_2 } from '@/consts'
import ordinalSuffixes from '@/helpers/ordinal-suffixes';
const API_V2 = 'v2';

const RECAP_USAGE_TYPES = ['mins', 'data', 'txts', 'pxts']

const recap_data_initial = {
    year: '',
    years_count: '',
    minutes: '',
    data: '',
    SMS: '',
    MMS: '',
}

const state = {
    is_recap_data_loading: false,
    is_recap_data_loaded: false,
    has_recap_data_error: false,
    recap_data: recap_data_initial,
};

const getters = {
    is_recap_data_loading: state => state.is_recap_data_loading,
    is_recap_data_loaded: state => state.is_recap_data_loaded,
    has_recap_data_error: state => state.has_recap_data_error,
    recap_data: state => state.recap_data,
};

const actions = {
    async GetRecapData({ getters, commit }, { Year = new Date().getFullYear() - 1, ProductType = 'mobileproduct' } = {}) {
        if (getters.is_recap_data_loaded && !getters.has_recap_data_error && Year === getters.recap_data?.year) return Promise.resolve(getters.recap_data);
        
        commit('SET_IS_RECAP_DATA_LOADING', true)
        commit('SET_IS_RECAP_DATA_LOADED', false)
        commit('SET_IS_RECAP_DATA_HAS_ERROR', false)
        
        const FirstNanos = new Date(Year, 0, 1).getTime() * 1000000
	    const FinalNanos = new Date(Year, 11, 31, 23, 59, 59).getTime() * 1000000

        const body = {
	    	CustomerUUID: getters.current_account_uid,
	    	ProductInstanceUUID: getters.customer_current_mobile_product_uuid,
	    	ProductType,
	    	Range: {
	    		FirstNanos,
	    		FinalNanos,
	    	  },
	    	IntervalNanos: 31 * 24 * 60 * 60 * 1000 * 1000000, // bucket size 31 days
	    };

        const recap_usage_promises = RECAP_USAGE_TYPES.map(type => axios.post(`${API_V2}/:spid/usage/${type}/histogram`, body));

        const recap_usages = await Promise.allSettled(recap_usage_promises);

        let recap_data = {
            year: Year,
            years_count: ordinalSuffixes(new Date().getFullYear() - getters.account_created_year),
            minutes: 0,
            data: 0,
            SMS: 0,
            MMS: 0,
        }

        recap_usages.forEach((recap_usage, index) => {
            const recap_usage_type = RECAP_USAGE_TYPES[index]
            const recap_usage_status = recap_usage.status
            const recap_usage_buckets = recap_usage.value?.result?.Buckets

            if (recap_usage_status === 'fulfilled' && recap_usage_buckets) {
                const recap_usage_reduced = recap_usage_buckets.reduce((acc, curr) => acc + curr, 0)

                switch (recap_usage_type) {
                    case 'data':
                        recap_data.data = +(recap_usage_reduced / MEGA_BASE_2).toFixed(0)
                        break
                    case 'mins':
                        recap_data.minutes = +(recap_usage_reduced / 60).toFixed(0)
                        break
                    case 'txts':
                        recap_data.SMS = recap_usage_reduced
                        break
                    case 'pxts':
                        recap_data.MMS = recap_usage_reduced
                        break
                }
            } else if (recap_usage_status === 'rejected') {
                commit('SET_IS_RECAP_DATA_HAS_ERROR', true)
            }
        })

        commit('SET_IS_RECAP_DATA_LOADING', false)
        commit('SET_IS_RECAP_DATA_LOADED', true)

        if (getters.has_recap_data_error) {
            commit('SET_RECAP_DATA', recap_data_initial)
        } else {
            commit('SET_RECAP_DATA', recap_data)
        }

        return Promise.resolve(getters.recap_data)
    },
};


const mutations = {
    SET_IS_RECAP_DATA_LOADING(state, is_recap_data_loading) {
        state.is_recap_data_loading = is_recap_data_loading
    },

    SET_IS_RECAP_DATA_LOADED(state, is_recap_data_loaded) {
        state.is_recap_data_loaded = is_recap_data_loaded
    },

    SET_IS_RECAP_DATA_HAS_ERROR(state, has_recap_data_error) {
        state.has_recap_data_error = has_recap_data_error
    },

    SET_RECAP_DATA(state, recap_data) {
        state.recap_data = recap_data
    },

    RESET_RECAP_DATA(state,) {
        state.is_recap_data_loading = false
        state.is_recap_data_loaded = false
        state.has_recap_data_error = false

        state.recap_data = {
            year: '',
            years_count: '',
            minutes: '',
            data: '',
            SMS: '',
            MMS: '',
        }
    },
};

export default {
    state,
    getters,
    mutations,
	actions,
};
