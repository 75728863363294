<template>
	<MountingPortal mountTo="#app-modal" append>
		<app-loader v-if="processing && animation_src" :animation_src="animation_src" />

	    <div class="app-modal" v-if="is_shown" v-show="!(processing && animation_src)" :class="className">
	        <div v-if="!hidden"
                class="app-modal__container"
                ref="app-modal-container"
                tabindex="-1"
                @keydown.esc.stop="onEscape"
                @keydown.enter.stop="$emit('onenter')"
            >
	            <app-card  class="app-modal__body">
					<app-loader v-if="processing && !animation_src" />

	                <app-error class="app-modal__error" :message="error_message" />

	                <div class="app-modal__header">
	                    <slot name=header />
	                </div>

	                <slot />

                    <img v-if="has_logo" :width="172" :height="56" class="app-modal__logo-image" :src="getImage(is_logo_reverted ? 'logo-reverted' : 'logo')">

	                <button v-if="!hide_close_button" class="app-modal__close" :class="has_logo ? 'app-modal__close--with-logo' : ''" @click.stop="hideModal(true)" :disabled="processing"></button>
	            </app-card>
	        </div>
	    </div>
	</MountingPortal>
</template>

<script>
import { mapGetters } from 'vuex'

import * as bodyScrollLock from 'body-scroll-lock'

import AppError from '@/components/app-error'
import AppLoader from '@/components/app-loader'
import AppCard from '@/components/app-card'

import errMessage from '@/helpers/err-message'

let scrollPosition = 0

export default {
    props: {
        processing:        { type: Boolean, default: false  },
        error:             { default: null                  },
		animation_src:     { type: String,  default: ''     },
        className:         { default: ''                    },
        hide_close_button: { type: Boolean, default: false  },
        has_logo:          { type: Boolean, default: false  },
        is_logo_reverted:  { type: Boolean, default: false  },
        hidden:            { type: Boolean, default: false  },
    },

    components: {
        AppError,
        AppLoader,
        AppCard,
    },

    data() {
        return {
            is_shown: false,
            this_el: null,
        }
    },
    mounted() {
        this.this_el = this.$el
    },

    watch: {
        processing: {
            immediate: false,
            handler(processing) {
                if (!processing) {
                    // setTimeout instead of using twice this.$nextTick
                    setTimeout(() => {
                        this.$refs['app-modal-container']?.focus();
                    }, 0);
                }
            }
        },
    },

    computed: {
        ...mapGetters(['getImage']),

        error_message() {
            return this.error instanceof Object ? errMessage(this.error) : this.error
        },
    },

    methods: {
        showModal() {
            // if (!this.is_shown) {
            //     if (this.withScrollLock) {
                    bodyScrollLock.disableBodyScroll(this.this_el)
            //     }

                this.is_shown = true

            //     if (this.ios) {
            //         scrollPosition = window.pageYOffset

            //         document.body.style.overflow = 'hidden'
            //         document.body.style.position = 'fixed'
            //         document.body.style.top = `-${scrollPosition}px`
            //         document.body.style.width = '100%'
            //     }

                document.documentElement.classList.add('modal-opened')

            // }

            // setTimeout instead of using twice this.$nextTick
            setTimeout(() => {
                this.$refs['app-modal-container']?.focus();
            }, 0);
        },

        hideModal(by_btn) {
            // if (this.is_shown) {
            //     if (this.withScrollLock) {
                    bodyScrollLock.enableBodyScroll(this.this_el)
            //     }

                this.is_shown = false

            //     if (this.ios) {
            //         document.body.style.removeProperty('overflow')
            //         document.body.style.removeProperty('position')
            //         document.body.style.removeProperty('top')
            //         document.body.style.removeProperty('width')

            //         window.scrollTo(0, scrollPosition)
            //     }

                document.documentElement.classList.remove('modal-opened')
            // }

            if (by_btn) {
                this.$emit('close-modal')
            }
        },

        onEscape() {
            if (!this.hide_close_button) {
                this.hideModal(true)
            }
        },
    },

    beforeDestroy() {
		if (this.is_shown) {
			this.hideModal()
		}
    },
}
</script>

<style lang="scss">
    .app-modal {
        --input-text-color: #{$input-text-color-active};
        --input-bg-color: #{$input-bg-color-active};
        --input-icon-color: #{$input-icon-color-active};
        --input-border-width: #{$input-border-width-active};
        --input-border-color: #{$input-border-color-active};
        --input-label-text-color: #{$input-label-text-color-active};

        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: auto;
        display: flex;
        height: auto;
        background-color: rgba($white, 0.8);
        z-index: $z-index-modal;

        &__container {
            @include container;

            padding-top: 20px;
            padding-bottom: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            outline: none;
        }

        &__body {
            position: relative;
            width: 690px;
            max-width: 100%;
            padding: 80px;

            @include media-bp(tab) {
                padding: 40px;
            }
            @include media-bp(mob) {
                padding: 15px;
            }

            .app-error {
                border-radius: 0;
            }
        }

        &__error {
            margin-bottom: 24px;
        }

        &__header {
            text-align: center;
        }

        &__logo-image {
            position: absolute;
            top: 24px;
            left: 24px;
            width: auto;
            height: 56px;
            z-index: 1;

            @include media-bp(tab) {
                height: 32px;
            }

            @include media-bp(mob) {
                left: 15px;
                height: 32px;
            }
        }

        &__close {
            @include icon(close);

            position: absolute;
            top: 16px;
            right: 16px;
            padding: 8px;
            cursor: pointer;

            color: $text-color-base;

            &:hover {
                color: $brand-color-primary;
            }

            &#{&}--with-logo {
                top: 36px;

                @include media-bp(tab) {
                    top: 24px;
                }
            }
        }

        &.full-screen {
            height: 100%;

            .app-modal__container {
                overflow-x: hidden;
                justify-content: flex-start;
                max-width: unset;
                width: 100%;
                height: 100%;
                padding: 0;
            }

            .app-modal__body {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                width: 100%;
                min-height: 100%;
                box-shadow: none;
                padding: 24px 40px;
                border: none;
                border-radius: 0;
                background-color: transparent;
                overflow-y: scroll;

                @include media-bp(mob) {
                    padding: 0;
                }
            }

            .app-modal__close {
                font-size: 24px;
                z-index: 1;

                @include media-bp(tab) {
                    &:hover {
                        color: $text-color-base;
                    }
                }

                @include media-bp(mob) {
                    right: 7px;
                }
            }
        }

        @include add-layout-styles('modal');
        @include add-theme-styles('modal');
    }
</style>
